import axios from 'axios';
import { UrlHelper } from "../helpers/url.helper"
import { clientService } from "./client.service"
import { PermissionUtil } from "../helpers/permission.util"
import { CryptUtils } from '../helpers/crypt.utils'
import { settingService } from "../services/setting.service"
import { CacheKeys } from "../enums/cache-keys"
import { CacheUtils } from '../helpers/cache.utils';
import { DefaultRole } from "../enums/default-role";
import { ClientUserStatus } from "../enums/client-user-status";

export const userService = {
    login,
    loginStep2,
    verfiyOTP,
    reset2FA,
    reset2FaApp,
    enable2FA,
    getRecoveryCodes,
    isInstall2FaApp,
    logout,
    loadProfile,
    loadWidgetPermission,
    loadProfileOnly,
    getEditProfile,
    getUserinfo,
    isAdmin,
    isBelongToRole,
    clientUserToken,
    activateClientUser,
    forgotPwd,
    list,
    listActive,
    get,
    save,
    deleteUser,
    uploadProfile,
    sendInvite,
    updateClientUserStatus,
    validEmail,
    changePassword,
    updateProfile,
    getUserId,
    getUserStatus,
    clearCache,

    forgotPassword,
    forgotPasswordValidToken,
    setNewPassword,

    getMyRoles,

    uploadFile,

    setToken,
    getToken,
    clearToken,

    setLockScreen,
    getLockScreen,

    eventLog,
    eventLogUnread,
    eventLogById,
    markViewEventLog,
    deleteEventLog,

    recentActivity,
    recentActivityET,

    myActivity,

    setAcceptPolicy
};

async function login(email, password) {
    const url = UrlHelper.resolveApiUrl('user/Login');

    const result = await axios.post(url, {
        username: email,
        password: password,
        id: "0",
        salt: "string"
    });

    return result.data
}

async function loginStep2(code, recoveryCode) {
    const url = UrlHelper.resolveApiUrl('user/loginStep2');
    const obj = {
        rememberClient: false,
        code: code,
        recoveryCode: recoveryCode
    };

    const result = await axios.post(url, obj);

    return result.data
}

async function verfiyOTP(code) {
    const url = UrlHelper.resolveApiUrl('user/loginStep2');
    const obj = {
        rememberClient: false,
        code: code,
        recoveryCode: null,
        verifyOnly: true,
    };

    const result = await axios.post(url, obj);

    return result.data
}

async function reset2FA() {
    const url = UrlHelper.resolveApiUrl('user/resetTwoFactorAuthenticator');
    const result = await axios.get(url);
    return result.data;
}

async function reset2FaApp(arrUserIds) {
    try {
        const clientId = clientService.getClientId();
        const url = UrlHelper.resolveApiUrl(`client/reset2FA?clientId=${clientId}`);
        const result = await axios.post(url, arrUserIds);
        return result.data;
    }
    catch (err) {
        return { status: 1, message: err };
    }
}

async function enable2FA(enable) {
    const clientId = clientService.getClientId();
    const url = UrlHelper.resolveApiUrl(`user/enableTwoFactorAuthenticator?clientId=${clientId}&enable=${enable}`);
    const result = await axios.post(url);
    return result.data;
}

async function getRecoveryCodes() {
    const url = UrlHelper.resolveApiUrl(`user/getRecoveryCodes`);
    const result = await axios.post(url);
    return result.data.data;
}

async function isInstall2FaApp() {
    const url = UrlHelper.resolveApiUrl(`user/getRecoveryCodes`);
    const result = await axios.post(url);

    if (result.data.status == 1)
        return false;

    return true;
}

function logout() {
    const token = getToken();
    if (token) {
        const config = { headers: { Authorization: `Bearer ${token}` } };
        const url = UrlHelper.resolveApiUrl('user/logout');

        try {
            axios.post(url, null, config);
        }
        catch (err) {
            console.log('logout', err);
        }
    }

    const bkLang = localStorage.getItem('lang');

    localStorage.clear();
    sessionStorage.clear();

    if (bkLang)
        localStorage.setItem('lang', bkLang);
}

async function loadProfile() {
    const strData = localStorage.getItem(CacheKeys.LoginUserInfo);

    if (CacheUtils.allowCacheData() && strData) {
        const userData = CacheUtils.getCache(CacheKeys.LoginUserInfo, true);
        const clientId = clientService.getClientId()
        const clientUsers = userData.user.clientUsers.find(a => a.clientId == clientId)
        PermissionUtil.setPermission(clientUsers?.userRoles, true);

        return userData;
    }

    try {
        const url = UrlHelper.resolveApiUrl('user/getUserInfo');
        const ret = await axios.get(url);

        if (ret.data.status == 0) {
            localStorage.setItem('userid', ret.data.data.user.id);
            CacheUtils.setCache('user', ret.data.data.user, true);

            const lstClients = ret.data.data.clients
            if (CacheUtils.allowCacheData() && ret.data.status == 0 && lstClients?.length > 0)
                localStorage.setItem(CacheKeys.ClientList, CryptUtils.Encrypt(JSON.stringify(lstClients)))

            const clientId = clientService.getClientId()
            const clientUsers = ret.data.data.user.clientUsers.find(a => a.clientId == clientId)
            PermissionUtil.setPermission(clientUsers?.userRoles, true)

            const hiddenSetting = await settingService.getHiddenSetting();
            localStorage.setItem(CacheKeys.CacheImage, hiddenSetting.BrowserCacheImage)
            localStorage.setItem(CacheKeys.CacheData, hiddenSetting.BrowserCacheData)

            if (!hiddenSetting.BrowserCacheImage)
                CacheUtils.clearImageCache()

            if (!hiddenSetting.BrowserCacheData) {
                CacheUtils.clearDataCache()
                CacheUtils.clearPattern("pogo")
            }

            if (CacheUtils.allowCacheData() && ret.data.data) {
                CacheUtils.setCache(CacheKeys.LoginUserInfo, ret.data.data, true);

                setTimeout(() => {
                    CacheUtils.clearPattern(CacheKeys.LoginUserInfo);
                }, 1000 * 60 * 5); // clear cache every 5 minutes
            }

            return ret.data.data;
        }
    }
    catch (err) {
        console.log('loadProfile. user/getUserInfo', err);
    }

    return null;
}

async function loadWidgetPermission() {
    const strData = localStorage.getItem(CacheKeys.LoginUserInfo);

    if (CacheUtils.allowCacheData() && strData) {
        const userData = CacheUtils.getCache(CacheKeys.LoginUserInfo, true);
        const clientId = clientService.getClientId();
        const clientUsers = userData.user.clientUsers.find(a => a.clientId == clientId);

        return PermissionUtil.getWidgetPermission(clientUsers?.userRoles);
    }

    try {
        const url = UrlHelper.resolveApiUrl('user/getUserInfo');
        const ret = await axios.get(url);

        if (ret.data.status == 0) {
            localStorage.setItem('userid', ret.data.data.user.id);
            CacheUtils.setCache('user', ret.data.data.user, true);

            const lstClients = ret.data.data.clients;
            if (CacheUtils.allowCacheData() && ret.data.status == 0 && lstClients?.length > 0)
                localStorage.setItem(CacheKeys.ClientList, CryptUtils.Encrypt(JSON.stringify(lstClients)));

            const clientId = clientService.getClientId();
            const clientUsers = ret.data.data.user.clientUsers.find(a => a.clientId == clientId);

            return PermissionUtil.getWidgetPermission(clientUsers?.userRoles);
        }
    }
    catch (err) {
        console.log('loadWidgetPermission. user/getUserInfo', err);
    }

    return null;
}


async function loadProfileOnly() {
    try {
        const url = UrlHelper.resolveApiUrl('user/getUserInfo');
        const ret = await axios.get(url);
        return ret.data.data;
    }
    catch (err) {
        console.log('loadProfileOnly. /user/getUserInfo', err);
    }

    return null;
}


function isAdmin() {
    return isBelongToRole(DefaultRole.ADMINISTRATOR);
}

function isBelongToRole(roleName) {

    if (roleName) {
        const user = getUserinfo();
        const clientId = clientService.getClientId();

        if (user && clientId > 0) {
            const clientUser = user.clientUsers.find(a => a.clientId == clientId);
            if (clientUser) {
                const userRole = clientUser.userRoles.find(a => a.name?.toUpperCase() == roleName.toUpperCase());
                return userRole ? userRole.isActive && userRole.isSelected : false;
            }
        }
    }

    return false;
}

async function getEditProfile() {
    const url = UrlHelper.resolveApiUrl('user/getUserInfo');
    const ret = await axios.get(url);
    if (ret.data.status == 0)
        return ret.data.data.user;

    return null;
}


function getUserinfo() {
    const userData = localStorage.getItem('user')
    if (userData)
        return CacheUtils.getCache('user', true);

    return null;
}

async function clientUserToken(token, email) {
    const url = UrlHelper.resolveApiUrl(`user/clientUserToken`);

    try {
        const result = await axios.post(url, {
            email, token
        });
        return result.data;
    }
    catch (err) {
        return { status: 1, message: err }
    }
}

async function activateClientUser(obj) {
    const url = UrlHelper.resolveApiUrl(`user/activateClientUser`);

    try {
        const result = await axios.post(url, obj);
        return result.data;
    }
    catch (err) {
        return { status: 1, message: err }
    }
}

async function getUserStatus(userId) {
    if (!userId)
        return { status: 1, message: 'Can not get status null user' };

    try {
        const strData = localStorage.getItem(CacheKeys.UsersStatus);
        let users = [];

        if (CacheUtils.allowCacheData() && strData) {
            users = CacheUtils.getCache(CacheKeys.UsersStatus);
            const user = users.find(a => a.id === userId);
            if (user)
                return user;
        }

        const clientId = clientService.getClientId();
        const url = UrlHelper.resolveApiUrl(`user/checkStatus?clientId=${clientId}`);
        const result = await axios.post(url, [userId]);

        if (result.data.status == 0) {
            users = users.concat(result.data.data);

            if (CacheUtils.allowCacheData() && users?.length > 0)
                CacheUtils.setCache(CacheKeys.UsersStatus, users);

            const user = users.find(a => a.id === userId);
            if (user)
                return user;
        }

        return null;
    }
    catch (err) {
        return { status: 1, message: err };
    }
}

async function forgotPwd(email) {
    const url = UrlHelper.resolveApiUrl(`user/forgotPassword`);

    try {
        const result = await axios.post(url, { email });
        return result.data;
    }
    catch (err) {
        return { status: 1, message: err }
    }
}

async function list(kw, clientUserStatus, pageIndex, pageSize, sortField, sortDir) {
    const clientId = clientService.getClientId();
    const direct = sortDir == 'asc' ? 'Ascending' : 'Descending';
    const qStatus = clientUserStatus != null && clientUserStatus >= 0 ? `&status=${clientUserStatus}` : '';

    const url = UrlHelper.resolveApiUrl(`client/user?clientId=${clientId}&keyword=${kw}${qStatus}&page=${pageIndex}&pageSize=${pageSize}&sortField=${sortField}&sortDir=${direct}&showPass=false`);

    const result = await axios.get(url)
    return result.data
}

async function listActive() {
    const strData = localStorage.getItem(CacheKeys.ListActiveUser);
    if (CacheUtils.allowCacheData() && strData)
        return CacheUtils.getCache(CacheKeys.ListActiveUser, true);

    const ret = await list('', ClientUserStatus.Active, 1, 999999, 'firstName', 'asc');
    const lstActive = ret.status == 0 ? ret.data.items : [];

    if (lstActive) {
        for (let item of lstActive)
            item.fullName = `${item.firstName} ${item.lastName}`;
    }

    if (CacheUtils.allowCacheData() && lstActive?.length > 0)
        CacheUtils.setCache(CacheKeys.ListActiveUser, lstActive, true);

    return lstActive;
}


async function get(id) {
    const clientId = clientService.getClientId()
    const url = UrlHelper.resolveApiUrl(`client/AddUser?clientId=${clientId}&id=${id}`);

    let result = await axios.get(url);
    return result.data;
}

async function save(obj) {
    const clientId = clientService.getClientId()
    const url = UrlHelper.resolveApiUrl(`client/addUser`);

    try {
        obj.clientIds = [clientId]
        const result = await axios.post(url, obj);
        return result.data;
    }
    catch (err) {
        return { status: 1, message: err }
    }
}

async function deleteUser(arrIds) {
    const clientId = clientService.getClientId()
    const url = UrlHelper.resolveApiUrl(`client/deleteUser?clientId=${clientId}`);

    try {
        const result = await axios.post(url, arrIds);
        return result.data;
    }
    catch (err) {
        return { status: 1, message: err }
    }
}

async function uploadProfile(formData) {
    const url = UrlHelper.resolveApiUrl(`user/uploadFile`);

    try {
        const result = await axios.post(url, formData);
        return result.data;
    }
    catch (err) {
        return { status: 1, message: err }
    }
}

async function sendInvite(obj) {
    const query = `clientId=${obj.clientId}&firstName=${obj.firstName}&lastName=${obj.lastName}&email=${obj.email}`
    const url = UrlHelper.resolveApiUrl(`client/CreateClientUserToken?${query}`);

    try {
        const result = await axios.post(url);
        return result.data;
    }
    catch (err) {
        return { status: 1, message: err }
    }
}

async function updateClientUserStatus(clientId, userId, status) {
    const url = UrlHelper.resolveApiUrl(`client/updateClientUserStatus?clientId=${clientId}&userId=${userId}&status=${status}`);

    try {
        const result = await axios.post(url);
        return result.data;
    }
    catch (err) {
        return { status: 1, message: err }
    }
}

async function validEmail(email, id) {
    const clientId = clientService.getClientId()
    const url = UrlHelper.resolveApiUrl(`client/user?clientId=${clientId}&email=${email}&page=1&pageSize=999999&sortField=Id`);
    const result = await axios.get(url)

    if (result.data.status == 0) {
        const items = result.data.data.items

        if (id == 0) {
            if (items && items.length > 0)
                return false
            else
                return true
        }
        else if (id > 0) {
            if (items && items.length > 0)
                return items[0].id == id
            else
                return true;
        }
    }

    return false
}

async function changePassword(obj) {
    const url = UrlHelper.resolveApiUrl(`user/changePassword`);

    try {
        const result = await axios.post(url, obj);
        return result.data;
    }
    catch (err) {
        return { status: 1, message: err }
    }
}

async function updateProfile(obj) {
    const url = UrlHelper.resolveApiUrl(`user/updateProfile`);

    try {
        const result = await axios.post(url, obj);
        return result.data;
    }
    catch (err) {
        return { status: 1, message: err }
    }
}

function getUserId() {
    return parseInt(localStorage.getItem('userid') || '0')
}

async function forgotPassword(email, siteDomain, sendFromEmail, sendFromName) {
    try {
        const query = `?email=${email}&siteDomain=${siteDomain}&sendFromEmail=${sendFromEmail}&sendFromName=${sendFromName}`
        const url = UrlHelper.resolveApiUrl(`user/forgotPassword${query}`);
        const result = await axios.post(url);
        return result.data;
    }
    catch (err) {
        return { status: 1, message: err }
    }
}

async function forgotPasswordValidToken(email, token) {
    try {
        const url = UrlHelper.resolveApiUrl(`user/userToken`);
        const result = await axios.post(url, {
            email,
            token
        });
        return result.data;
    }
    catch (err) {
        return { status: 1, message: err }
    }
}

async function setNewPassword(email, password, token) {
    try {
        const url = UrlHelper.resolveApiUrl(`user/updatePassword`);
        const result = await axios.post(url, {
            email,
            password,
            token
        });
        return result.data;
    }
    catch (err) {
        return { status: 1, message: err }
    }
}

function getMyRoles() {
    const strJson = localStorage.getItem('user')
    if (strJson) {
        const user = JSON.parse(CryptUtils.Decrypt(strJson))
        const clientId = clientService.getClientId()
        const clientUser = user.clientUsers.find((a) => a.clientId == clientId)

        if (clientUser) {
            const userRoles = clientUser.userRoles.filter(a => a.isSelected)
            return userRoles
        }
    }

    return []
}

async function uploadFile(formData) {
    const url = UrlHelper.resolveApiUrl(`user/uploadFile`);

    try {
        const result = await axios.post(url, formData);
        return result.data;
    }
    catch (err) {
        return { status: 1, message: err }
    }
}

function setToken(jwt) {
    if (jwt) {
        const encrypt = CryptUtils.Encrypt(jwt)
        localStorage.setItem('jwt', encrypt)
    }
}

function clearToken() {
    if (localStorage.getItem('jwt'))
        localStorage.removeItem('jwt');
}

function getToken() {
    const encrypt = localStorage.getItem('jwt')

    if (encrypt) {
        const jwt = CryptUtils.Decrypt(encrypt)
        return jwt
    }

    return null
}

function setLockScreen(value) {
    localStorage.setItem('lockscreen', value)
}

function getLockScreen() {
    return localStorage.getItem('lockscreen') || 0
}

async function eventLog(eventId, pageIndex, pageSize, sortField, sortDir) {
    try {
        const qEventId = eventId == null || eventId == undefined ? "" : `&eventId=${eventId}`;
        const clientId = clientService.getClientId()
        const direct = sortDir == 'asc' ? 'Ascending' : 'Descending'
        const url = UrlHelper.resolveApiUrl(`user/eventLog?clientId=${clientId}${qEventId}&page=${pageIndex}&pageSize=${pageSize}&sortField=${sortField}&sortDir=${direct}`);

        const result = await axios.get(url)
        return result.data
    }
    catch (err) {
        return { status: 1, message: err };
    }

}

async function eventLogUnread() {
    try {
        const sortDir = 'asc';
        const clientId = clientService.getClientId();
        const direct = sortDir == 'asc' ? 'Ascending' : 'Descending';
        const sortField = "Id";

        const url = UrlHelper.resolveApiUrl(`user/eventLog?clientId=${clientId}&viewed=false&page=1&pageSize=1&sortField=${sortField}&sortDir=${direct}`);
        const result = await axios.get(url);

        if (result.data.status == 0)
            return result.data.data.pageItem.totalItems || 0;
    }
    catch (err) {
        console.log("event log unread", err);
    }

    return 0;
}

async function eventLogById(eventId) {
    const ret = await eventLog(eventId, 1, 9999, "Id", "desc");

    if (ret.status == 0 && ret.data.items?.length > 0)
        return ret.data.items[0];

    return null;
}

async function markViewEventLog(arrIds) {
    try {
        const clientId = clientService.getClientId()
        const url = UrlHelper.resolveApiUrl(`user/markViewEventLog?clientId=${clientId}`);

        const result = await axios.post(url, arrIds)
        return result.data
    }
    catch (err) {
        return { status: 1, message: err };
    }

}

async function deleteEventLog(arrIds) {
    try {
        const clientId = clientService.getClientId()
        const url = UrlHelper.resolveApiUrl(`user/deleteEventLog?clientId=${clientId}`);

        const result = await axios.post(url, arrIds)
        return result.data
    }
    catch (err) {
        return { status: 1, message: err };
    }
}

async function recentActivity(count) {
    const pageIdx = 1;
    const pageSize = count || 50;
    const clientId = clientService.getClientId();
    const exludeAssignee = true;

    const url = UrlHelper.resolveApiUrl(`client/getActivityStream?id=${clientId}&exludeAssignee=${exludeAssignee}&pageIdx=${pageIdx}&pageSize=${pageSize}&isct=0`);
    const { data } = await axios.post(url);

    if (data.status == 0)
        return data.data.histories;

    return [];
}

async function recentActivityET(count) {
    const pageIdx = 1;
    const pageSize = count || 50;
    const clientId = clientService.getClientId();
    const exludeAssignee = true;

    const url = UrlHelper.resolveApiUrl(`client/getActivityStream?id=${clientId}&exludeAssignee=${exludeAssignee}&pageIdx=${pageIdx}&pageSize=${pageSize}&isct=1`);
    const { data } = await axios.post(url);

    if (data.status == 0)
        return data.data.histories;

    return [];
}

async function myActivity(pageIdx, pageSize) {
    try {
        const clientId = clientService.getClientId();
        const url = UrlHelper.resolveApiUrl(`client/getPersonalStream?id=${clientId}&pageIdx=${pageIdx}&pageSize=${pageSize}&isct=0`);
        const { data } = await axios.post(url);

        if (data.status == 0)
            return data;
    }
    catch (err) {
        return { status: 1, message: err };
    }
}

function clearCache() {
    CacheUtils.clearPattern(CacheKeys.ListActiveUser);
    CacheUtils.clearPattern(CacheKeys.UsersStatus);
}

async function setAcceptPolicy() {
    try {
        const clientId = clientService.getClientId();
        const url = UrlHelper.resolveApiUrl(`user/setAcceptPolicy?clientId=${clientId}`);
        const { data } = await axios.post(url);

        if (data.status == 0)
            return data;
    }
    catch (err) {
        return { status: 1, message: err };
    }
}