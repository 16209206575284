import moment from "moment";
import axios from 'axios';
import { DateUtils } from "./date.utils";

export const Utils = {
    formatDate,
    formatNumber,
    formatCurrency,
    setLayoutMode,
    getLayoutMode,
    setLanguage,
    getLanguage,
    validPassword,
    getPostalCode,
    newGUID,
    hashStr2Num,
    get2Char,
    getImgNACss,
    getFileExt,
    getFileName,
    isImage,
    getFileIcon,
    str2CharCode,
    charCode2Str,
    generateAlias,
    fileSizeInText,
    detectTextLink,
    removeImageWidthHeight,

    nextChargedDate
}


function formatDate(date, format) {
    if (date)
        return moment(date).format(format || window.DateFormat);

    return "";
}

function formatNumber(number, decimalDigit, langCode) {
    const lang = langCode || "nb-NO";

    if (decimalDigit > 0)
        return new Intl.NumberFormat(lang, { minimumFractionDigits: decimalDigit, maximumFractionDigits: decimalDigit }).format(number);

    return new Intl.NumberFormat(lang).format(number);
}

function formatCurrency(amount, currency, currencyOnRight) {
    currency = currency || "NOK";
    currencyOnRight = currencyOnRight || true;

    if (currencyOnRight)
        return `${this.formatNumber(amount)} ${currency}`;

    return `${currency} ${this.formatNumber(amount)} `;
}

function setLayoutMode(mode) {
    mode = mode || 'dark';
    localStorage.setItem('layout-mode', mode);
}

function getLayoutMode() {
    return localStorage.getItem('layout-mode') || 'dark';
}

function setLanguage(lang) {
    lang = lang || process.env.VUE_APP_I18N_LOCALE;
    localStorage.setItem('lang', lang);
}

function getLanguage() {
    return localStorage.getItem('lang') || process.env.VUE_APP_I18N_LOCALE;
}

function validPassword(rawPassword) {
    // https://stackoverflow.com/questions/5142103/regex-to-validate-password-strength
    /* 
    Regex to validate password strength
    My password strength criteria is as below:
        8 characters length
        2 letters in Upper Case
        1 Special Character (!@#$&*)
        2 numerals (0-9)
        3 letters in Lower Case 
    */

    /* 
    ^                         Start anchor
    (?=.*[A-Z])               Ensure string has one uppercase letter.
    (?=.*[!@#$&*])            Ensure string has one special case letter.
    (?=.*[0-9])               Ensure string has one digit.
    (?=.*[a-z].*[a-z].*[a-z]) Ensure string has one lowercase letter.
    .{8,20}                   Ensure string is of length 8-20.
    $                         End anchor. 
    */
    const pattern = '^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,20}$'

    const reg = new RegExp(pattern)

    return reg.test(rawPassword)
}


async function getPostalCode(postNr, countryCode) {
    try {
        const code = countryCode || 'no'
        const url = `https://api.bring.com/shippingguide/api/postalCode.json?clientUrl=https://api.bring.com&country=${code}&pnr=${postNr}`
        let result = await axios.get(url);
        if (result.data.valid)
            return result.data.result
    }
    catch (err) {
        console.log('getPostalCode', err)
    }

    return ''
}

function newGUID() {

    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
        .replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });

}

function hashStr2Num(str) {
    if (!str)
        return 0

    let arr = str.split('')

    return arr.reduce(
        (hashCode, currentVal) =>
        (hashCode =
            currentVal.charCodeAt(0) +
            (hashCode << 6) +
            (hashCode << 16) -
            hashCode),
        0
    )
}

function get2Char(str) {
    try {
        str = str || 'NA'
        const arrTemp = str.split(' ');
        let arr = []
        let result = '';

        for (const item of arrTemp) {
            if (item.trim() != '')
                arr.push(item);
        }

        if (arr.length > 1)
            result = arr[0].charAt(0) + arr[arr.length - 1].charAt(0);
        else {
            if (arr[0].length > 1)
                result = arr[0].substring(0, 2);
            else
                result = arr[0].charAt(0);
        }

        return result.toUpperCase()
    }
    catch (err) {
        console.log('get2Char', str);
    }
    return 'NA';
}

function getImgNACss(str) {
    str = get2Char(str)
    const imageNA = [
        'img-na-primary',
        'img-na-secondary',
        'img-na-success',
        'img-na-info',
        'img-na-warning',
        'img-na-danger',
        'img-na-light'
    ]

    let number = str && str.length == 2 ? str.charCodeAt(0) + str.charCodeAt(1) : hashStr2Num(str)

    if (number < 0) {
        number = number * -1
    }

    return imageNA[number % 7]
}

function getFileExt(fileName) {
    fileName = fileName || ''
    if (fileName.indexOf('.') >= 0)
        return fileName.split('.').pop(); // return "doc, docx, jpg, png"

    return ''
}

function getFileName(filePath) {

    if (filePath && filePath.indexOf('/') >= 0) {
        const idx = filePath.lastIndexOf('/');
        const fileName = filePath.substr(idx + 1);
        return fileName;
    }

    return filePath;
}

function isImage(fileName) {
    const ext = getFileExt(fileName)
    const imgExt = ['jpg', 'jpeg', 'gif', 'bmp', 'png']

    return imgExt.includes(ext)
}

function getFileIcon(fileName) {
    const ext = Utils.getFileExt(fileName)
    const arrImage = ['gif', 'png', 'jpg', 'jpeg', 'bmp']

    if (arrImage.includes(ext))
        return `mdi mdi-file-image-outline`

    else if (ext == 'pdf')
        return 'mdi mdi-file-pdf-box'

    else if (ext == 'doc' || ext == 'docx')
        return `mdi mdi-file-word-box-outline`

    else if (ext == 'xls' || ext == 'xlsx')
        return `mdi mdi-file-excel-outline`

    else if (ext == 'ppt' || ext == 'pptx')
        return `mdi mdi-file-powerpoint-box-outline`

    else if (ext == 'txt')
        return `ri-file-text-line`

    else if (ext == 'zip')
        return `ri-file-zip-line`

    return 'mdi mdi-file-outline'
}

function str2CharCode(text) {
    if (!text)
        return []

    let arr = []
    for (let i = 0; i < text.length; i++)
        arr.push(text.charCodeAt(i))

    return arr
}

function charCode2Str(arrCharCode) {
    if (!arrCharCode)
        return ""

    let text = String.fromCharCode(...arrCharCode)

    return text
}

function generateAlias(inputText, maxLength) {
    maxLength = maxLength || 250

    //lower chars
    inputText = inputText.replace(/æ|å|ä|à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    inputText = inputText.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    inputText = inputText.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    inputText = inputText.replace(/ø|ö|ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    inputText = inputText.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    inputText = inputText.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    inputText = inputText.replace(/đ|ð/g, "d");
    inputText = inputText.replace(/ñ/g, "n");

    //upper chars
    inputText = inputText.replace(/Æ|Å|Ä|À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    inputText = inputText.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    inputText = inputText.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    inputText = inputText.replace(/Ø|Ö|Ó|Ò|Ỏ|Õ|Ọ|Ô|Ố|Ồ|Ổ|Ỗ|Ộ|Ơ|Ớ|Ờ|Ở|Ỡ|Ợ/g, "O");
    inputText = inputText.replace(/Ú|Ù|Ủ|Ũ|Ụ|Ư|Ứ|Ừ|Ử|Ữ|Ự/g, "U");
    inputText = inputText.replace(/Ý|Ỳ|Ỷ|Ỹ|Ỵ/g, "Y");
    inputText = inputText.replace(/Đ|Ð/g, "D");
    inputText = inputText.replace(/Ñ/g, "N");

    inputText = inputText.replace(/!|@|%|\^|\*|\(|\)|\+|=|<|>|\?|\/|,|\.|:|;|'| |"|&|#|\[|\]|~|$|_/g, " ");

    // Remove invalid character
    var result = inputText.replace(/(^[^a-zA-Z0-9]+)|([^a-zA-Z0-9\-_\s])/g, "");
    //Replace all space with _
    result = result.replace(/\s+[-_]*/g, "-");
    result = result.replace(/-+-/g, "-"); //thay thế 2- thành 1-
    result = result.replace(/^-+|-+$/g, "");
    // trim space, - and _ of strim
    result = result.replace(/^[\s\-_]+|[\s\-_]+$/g, "");

    return result.substring(0, maxLength).toLowerCase();
}

function fileSizeInText(bytes, decimalNum, defaultUnit) {
    const K_UNIT = 1024;
    const SIZES = ["B", "KB", "MB", "GB", "TB", "PB"];
    bytes = bytes || 0;
    decimalNum = decimalNum || 2;

    if (bytes == 0)
        return "0 B";

    if (defaultUnit === "MB")
        return (bytes / (K_UNIT * K_UNIT)).toFixed(decimalNum) + " MB";

    const i = Math.floor(Math.log(bytes) / Math.log(K_UNIT));
    const resp = parseFloat((bytes / Math.pow(K_UNIT, i)).toFixed(decimalNum)) + " " + SIZES[i];

    return resp;
}

function detectTextLink(text) {
    return text;
}

function removeImageWidthHeight(html) {
    const jq = window.jQuery;

    if (html && typeof jq == "function") {
        const tmp = jq('<div></div>').html(html);
        tmp.find('img').removeAttr('width').removeAttr('height');
        html = tmp.html();

        return html;
    }

    return html;
}

function nextChargedDate(endDate) {
    let retDate = null;
    // debugger; // eslint-disable-line no-debugger

    if (typeof (endDate) == "string") {
        const arr = [-2, -1, 0, 1, 2, 3];
        const now = new Date();

        for (const val of arr) {
            let date = new Date(endDate);
            const chargedDate = DateUtils.addDays(date, val); // charged two day before end date

            if (chargedDate > now || DateUtils.format(chargedDate, 'yyyy-MM-DD') == DateUtils.format(now, 'yyyy-MM-DD')) {
                retDate = chargedDate;
                break;
            }
        }
    }

    // console.log('End date', endDate, 'next date', retDate);

    return retDate;
}